/* eslint-disable no-console */
import { createApi, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";

import type { IBadgesResponse } from "../../../features/certificateDownload/api/queries.app";
import { CertificateDownloaderApiRoutes } from "../../../features/certificateDownload/api/routes";
import type { IGetCertificateArgs } from "../types/certificateProps.query.types";

export const certificatePropsApi = createApi({
    reducerPath: "certificateProps",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.API_URL }),
    tagTypes: ["getCertificates", "getCertificateDetails"],
    endpoints: (build) => ({
        getCertificateProps: build.query<IBadgesResponse, IGetCertificateArgs>({
            query: (slugs) => {
                return {
                    url: `/api/public/badge?orga_slug=${slugs.orga_slug}&event_slug=${slugs.certificate_slug}`,
                    method: "GET",
                    headers: {
                        accept: "application/json, text/plain, */*",
                    },
                };
            },
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (res: IBadgesResponse) => res,
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response: FetchBaseQueryError) => response,
            providesTags: (_result, _error, slugs) => [{ type: "getCertificates", slugs }],
        }),
        getCertificateDetails: build.query<ICertificateDetails, IGetCertificateArgs>({
            query: (slugs) => ({ url: CertificateDownloaderApiRoutes.getDetails(slugs.token || ""), method: "GET" }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (res: ICertificateDetails) => res,
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response: FetchBaseQueryError) => response,
            providesTags: (_result, _error, slugs) => [{ type: "getCertificateDetails", slugs }],
        }),
    }),
});

export const { useGetCertificatePropsQuery, useGetCertificateDetailsQuery } = certificatePropsApi;
